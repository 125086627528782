import styles from '../Home.module.css'
import React, { useState, useRef, useEffect } from 'react'
import * as echarts from 'echarts'
import DateSelector from '../../component/DateSelector/DateSelector'
import request from '../../../util/request'
export default function CollectCoupons() {
  const date_selector_ref = useRef()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [isall, setIsAll] = useState(true)
  useEffect(() => {
    defaultDate()
  }, [])
  // 打开日期选择器
  const openDateSelector = () => {
    date_selector_ref.current.showModal()
  }
  // 选择日期后回调返回开始和结束时间
  function getDateBack(value) {
    setIsAll(false)
    setStartDate(value.startDate)
    setEndDate(value.endDate)
    getCollectCoupons(value)
  }
  // 点击全部
  function defaultDate() {
    setIsAll(true)
    setStartDate(null)
    setEndDate(null)
    getCollectCoupons({ startDate: null, endDate: null })
  }
  // 券码领取统计
  const getCollectCoupons = value => {
    request
      .post({
        method: 'iot.back.fws.iot.ls.dashboard.banner',
        biz_content: JSON.stringify({
          begin: value.startDate,
          end: value.endDate,
          page: '0',
          size: '20',
        }),
      })
      .then(res => {
        if (res.data.code == '10000') {
          let arr = [[], []]
          let df = 6
          let l = res.data.result.length
          let n = df - l
          res.data.result.map(e => {
            arr[0].push(e.name)
            arr[1].push(e.num)
          })
          for (let i = 0; i <= n; i++) {
            arr[0].push('')
            arr[1].push('')
          }
          setLqsj(arr)
        }
      })
  }
  // 领券数据
  const [lqsj, setLqsj] = useState([[], []])
  useEffect(() => {
    lqsjInit()
  }, [lqsj])
  const lq_option = {
    xAxis: { show: false },
    grid: {
      containLabel: true,
      top: 10,
      left: 0,
      bottom: 0,
      right: 0,
    },
    // tooltip: {
    //     trigger: 'axis',
    //     axisPointer: {
    //       type: 'none'
    //     }
    // },
    yAxis: {
      type: 'category',
      inverse: true,
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: {
        color: '#ffffff',
        fontSize: 18,
        formatter: function (value, index) {
          return lqsj[1][index]
        },
      },
      position: 'right',
      data: lqsj[0],
    },
    series: [
      {
        name: '领券数据',
        type: 'bar',
        barMaxWidth: 40,
        label: {
          show: true,
          color: '#ffffff',
          fontSize: 14,
          position: 'insideLeft',
          formatter: function (params) {
            return params.name
          },
        },
        showBackground: true,
        itemStyle: {
          // color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
          //   { offset: 0, color: '#1a93d8' },
          //   { offset: 1, color: '#67b8e6' },
          // ]),
        },
        // emphasis: {
        //     itemStyle: {
        //         color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
        //             { offset: 0, color: '#2378f7' },
        //             { offset: 0.7, color: '#2378f7' },
        //             { offset: 1, color: '#83bff6' }
        //         ])
        //     }
        // },
        data: lqsj[1],
      },
    ],
  }

  function lqsjInit() {
    let chartDom = document.getElementById('lqsj')
    let myChart = echarts.init(chartDom)
    lq_option && myChart.setOption(lq_option)
  }
  return (
    <>
      <div className={styles.box3}>
        <div className={styles.head}>
          <div className={styles.title}>领券数据</div>
          <div className={styles.date}>
            <div onClick={defaultDate} className={styles.allbox}>
              <div className={styles.all}>{isall ? <div></div> : null}</div>
              <span>全部</span>
            </div>
            <img
              src="https://sbag-small.oss-cn-huhehaote.aliyuncs.com/upload/img/web/ito/rl.png"
              alt=""
            />
            <span onClick={openDateSelector}>
              {!isall ? startDate + '至' + endDate : '日期选择'}
            </span>
          </div>
        </div>
        {/* chart */}
        <div className={styles.lqsj} id="lqsj">
          {' '}
        </div>
      </div>
      <DateSelector ref={date_selector_ref} getDateBack={getDateBack} />
    </>
  )
}
