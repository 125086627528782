import axios from "axios";
import md5 from "md5";
import { message } from "antd";
// const api = 'https://cat.shishangbag.vip/apiv3/'
// const api = 'https://ull.shishangbag.vip/apiv3/'
// const api = 'https://ultrue.shishangbag.vip/apiv3/'
const api = "https://miniapp.shishangbag.vip/apiv3/";
// 签名MD5加密

function formatDate() {
  var date = new Date();
  var YY = date.getFullYear() + "-";
  var MM =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  var hh =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  var mm =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  var ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return YY + MM + DD + " " + hh + mm + ss;
}
const dataConversion = (payload) => {
  let user = localStorage.getItem("user");
  let token = "";
  if (user) {
    user = JSON.parse(localStorage.getItem("user"));
    token = user.result.token;
  }

  const obj = {
    app_id: "20200608719606620987850752",
    biz_content: payload.biz_content ? payload.biz_content : "",
    format: "json",
    method: payload.method,
    nonce: `83552b17-c9a4-4bfb-9ceb-ef31dc82faa9${new Date().getTime()}${Math.ceil(
      Math.random() * 100000
    )}`,
    // releaseVersion: 'v20220112',
    timestamp: formatDate(),
    token: token ? token : "",
    type: "account",
    version: "1.1",
    microServiceIp: "",
  };
  // 排序过滤请求数据并转换成数组
  function sortFilterData(obj) {
    let arr = [];
    for (let i in obj) {
      if (obj[i]) {
        arr.push({ [i]: obj[i] });
      }
    }
    return arr.sort();
  }
  // 数组转换成签名数据
  function setQuery(arr) {
    let str = "";
    arr.map((e, i) => {
      if (i == arr.length - 1) {
        str +=
          Object.keys(e)[0] +
          "=" +
          Object.values(e)[0] +
          "0878b0d87e84486dad0a9856593fb7ac";
      } else {
        str += Object.keys(e)[0] + "=" + Object.values(e)[0] + "&";
      }
    });
    return str;
  }
  let pam = {};
  for (let i in obj) {
    if (obj[i]) {
      pam[i] = obj[i];
    }
  }
  pam.sign = md5(setQuery(sortFilterData(obj)));
  return pam;
};
const post = (data) => {
  var promise = new Promise((resolve, reject) => {
    let newData = dataConversion(data);
    axios.post(api, newData).then((res) => {
      if (res.data.code != 10000) {
        reject(res);
        message.error(res.data.sub_msg);
        return;
      }
      if (data.postPage == "page") {
        console.log(data);
        let datares = res.data.result;
        let page = {
          current: datares.number,
          data: datares.content,
          pageSize: datares.size,
          total: datares.totalElements,
          onlineNum: datares.onlineNum ? datares.onlineNum : "",
        };
        page.data.map((item, index) => (item.key = index));
        resolve(page);
        return;
      }
      if (data.postPage == "machinePage") {
        let datares = res.data.result.results;
        let totalOnline = res.data.result.totalOnline;
        console.log(totalOnline);
        console.log(res.data);
        let page = {
          current: datares.number,
          data: datares.content,
          pageSize: datares.size,
          total: datares.totalElements,
          totalOnline: res.data.result.totalOnline,
          onlineNum: datares.onlineNum ? datares.onlineNum : "",
        };
        page.data.map((item, index) => (item.key = index));
        resolve(page);
        return;
      }
      resolve(res);
    });
  });
  return promise;
};
const get = (data) => {
  var promise = new Promise((resolve, reject) => {
    let newData = dataConversion(data);
    resolve(newData);
  });
  return promise;
};
//新的调用方式
// 签名MD5加密
function new_dataConversion(data) {
  let user = localStorage.getItem("user");
  let timestamp = formatDate();
  let random = Math.ceil(Math.random() * 1000000000);
  let nonce = `83552b17-c9a4-4bfb-9ceb-ef31dc82faa9${new Date().getTime()}${random}`;
  let headMsg = {
    appid: "appid00000000000001",
    data: JSON.stringify(data || {}),
    nonce: nonce,
    timestamp: timestamp,
  };
  headMsg.sign = md5(
    `appid=appid00000000000001&data=${JSON.stringify(
      data || {}
    )}&nonce=${nonce}&timestamp=${timestamp}asd99wqe213*67sadkkjk*98^%$GHhjguiyu`
  );
  // console.log(
  //   `appid=appid00000000000001&data=${JSON.stringify(
  //     data || {}
  //   )}&nonce=${nonce}&timestamp=${timestamp}asd99wqe213*67sadkkjk*98^%$GHhjguiyu`,
  //   'before'
  // )
  return headMsg;
}
const new_post = (data) => {
  var promise = new Promise((resolve, reject) => {
    let newData = new_dataConversion(data.data);

    axios
      .post(
        "https://nowastestar.trashaus.cn/api/" + data.method,
        data.data ? data.data : {},
        {
          headers: newData,
        }
      )
      .then((res) => {
        if (res.data.code != 10000) {
          reject(res);
          return;
        }
        if (data.postPage == "page") {
          let datares = res.data.result;
          let page = {
            current: datares.number,
            data: datares.content,
            pageSize: datares.size,
            total: datares.totalElements,
            onlineNum: datares.onlineNum ? datares.onlineNum : "",
          };

          page.data.map((item, index) => (item.key = index));
          resolve(page);
          return;
        }
        resolve(res);
      });
  });
  return promise;
};
export default {
  post,
  get,
  new_post,
  api,
  imgUrl: "https:///image.shishangbag.vip",
};
