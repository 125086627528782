import React, { useState, useRef, useEffect } from 'react'
import * as echarts from 'echarts'
import styles from '../Home.module.css'
import '../../../util/china.js'
import Map from '../../component/Map'
import { Button } from 'antd'
import request from '../../../util/request'
import DateSelector from '../../component/DateSelector/DateSelector'
export default function DeviceMap() {
  const date_selector_ref = useRef()
  const [tdsj, setTdsj] = useState([[], []])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [data, setData] = useState({})
  const [mapData, setMapData] = useState([])
  const [countList, setCountList] = useState([])
  const [isall, setIsAll] = useState(true)
  const [province, setProvince] = useState({})
  const [active, setActive] = useState(1)
  useEffect(() => {
    defaultDate()
  }, [])

  useEffect(() => {
    getMapData()
    changeSwitch({ name: province.name || '' })
  }, [endDate])

  useEffect(() => {
    dttdsjInit()
  }, [tdsj])
  // 选择日期后回调返回开始和结束时间
  function getDateBack(value) {
    setIsAll(false)
    setStartDate(value.startDate)
    setEndDate(value.endDate)
  }
  // 打开日期选择器
  const openDateSelector = () => {
    date_selector_ref.current.showModal()
  }
  // 获取地图显示数据
  function getMapData(params) {
    request
      .post({
        method: 'iot.user.delivery.data.chinaMap',
        biz_content: JSON.stringify({
          startDate: startDate,
          endDate: endDate,
        }),
      })
      .then(res => {
        if (res.data.code == '10000') {
          setMapData(res.data.result)
        }
      })
  }
  // 地图投递数据 左侧
  const dttdsj_option = {
    xAxis: {
      show: false,
    },
    grid: {
      containLabel: true,
      top: 10,
      left: 0,
      bottom: 0,
      right: 20,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
      formatter: function (value, index) {
        return tdsj[1][index]
      },
    },
    yAxis: {
      type: 'category',
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        color: '#333333',
        fontSize: 18,
        formatter: function (value, index) {
          return tdsj[1][index]
        },
      },
      position: 'right',
      data: tdsj[0],
    },
    series: [
      {
        name: '',
        type: 'bar', //类型
        barMaxWidth: 40,
        label: {
          show: true,
          color: '#838385',
          position: 'insideLeft',
          fontSize: 14,
          formatter: function (params) {
            return params.name
          },
        },
        // backgroundStyle: {
        //   color: '#fff',

        // },
        // showBackground: true,
        itemStyle: {
          //柱状图样式
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
              { offset: 1, color: '#e4f8ef' },
              { offset: 0, color: '#5dcfc8' },
              // { offset: 1, color: (31, 191, 179, 0.71) },
            ]),
            barBorderRadius: 6,
            //             background: linear-gradient(101.17deg, rgba(31, 191, 179, 0.71) 17.92%, rgba(138, 255, 181, 0.16) 93.38%);
            // border-radius: 4px;
          },
        },
        data: tdsj[1],
      },
    ],
  }
  function dttdsjInit() {
    let chartDom = document.getElementById('dttdsj')
    let myChart = echarts.init(chartDom)
    dttdsj_option && myChart.setOption(dttdsj_option)
  }
  // 点击全部
  function defaultDate() {
    setIsAll(true)
    setProvince({ name: '' })
    setStartDate(null)
    setEndDate(null)
  }
  useEffect(() => {
    changeSwitch({ name: province.name || '' })
  }, [active])
  function changeSwitch(params) {
    if (active == 1) {
      changeTdsj(params)
    } else {
      changeLqsj(params)
    }
  }
  // 根据省份获取数据
  function changeTdsj(params) {
    setProvince(params)
    request
      .post({
        method: 'iot.user.delivery.data.map',
        biz_content: JSON.stringify({
          startDate: startDate,
          endDate: endDate,
          province: params ? params.name : '',
        }),
      })
      .then(res => {
        if (res.data.code == '10000') {
          let arr = [[], []]
          let df = 6
          let l = res.data.result.content.length
          let n = df - l
          let newlist = res.data.result.content.reverse()
          for (let i = 0; i <= n; i++) {
            arr[0].push('')
            arr[1].push('')
          }
          newlist.map((e, i) => {
            arr[0].push(e.branchName)
            arr[1].push(e.bottleNumber)
          })
          setTdsj(arr)
        }
      })
  }

  // 根据省份获取数据  --- 领券
  function changeLqsj(params) {
    params = params || province
    setProvince(params)
    request
      .post({
        method: 'iot.back.fws.iot.ls.dashboard.machine',
        biz_content: JSON.stringify({
          startDate: startDate,
          endDate: endDate,
          province: params ? params.name : '',
          pageSize: 10,
        }),
      })
      .then(res => {
        if (res.data.code == '10000') {
          console.log(res, '领券次数')
          let arr = [[], []]
          let df = 6
          let l = res.data.result.length
          let n = df - l
          let newlist = res.data.result.reverse()
          for (let i = 0; i <= n; i++) {
            arr[0].push('')
            arr[1].push('')
          }
          newlist.map((e, i) => {
            arr[0].push(e.name)
            arr[1].push(e.num)
          })
          setTdsj(arr)
        }
      })
  }
  return (
    <>
      <div className={styles.mapBox}>
        <div className={styles.left}>
          <div className={styles.head}>
            <div className={styles.title}>地图投递数据</div>
            <div className={styles.date}>
              <div onClick={defaultDate} className={styles.allbox}>
                <div
                  className={styles.all}
                  style={{ backgroundColor: '#fff', borderColor: '#ccc' }}
                >
                  {isall ? <div></div> : null}
                </div>
                <span>全部</span>
              </div>
              <img
                src="https://sbag-small.oss-cn-huhehaote.aliyuncs.com/upload/img/web/ito/rl2.png"
                alt=""
              />
              <span onClick={openDateSelector}>
                {!isall ? startDate + '至' + endDate : '日期选择'}
              </span>
            </div>
            <div className={styles.switch}>
              <div
                className={active == 1 ? styles.active : ''}
                onClick={() => {
                  setActive(1)
                }}
              >
                投递次数
              </div>
              {/* <div
                className={active == 2 ? styles.active : ''}
                onClick={() => {
                  setActive(2)
                }}
              >
                领券次数
              </div> */}
            </div>
          </div>
          {/* chart */}
          <div className={styles.dttdsj} id="dttdsj">
            {' '}
          </div>
        </div>

        <Map mapData={mapData} changeTdsj={changeSwitch} />
        {/* <CitysMap/> */}
        <div
          onClick={() => {
            changeSwitch({ name: '' })
          }}
          className={styles.allMap}
        >
          查看全国
        </div>
      </div>
      <DateSelector ref={date_selector_ref} getDateBack={getDateBack} />
    </>
  )
}
