import styles from '../Home.module.css'
import React, { useState, useRef, useEffect } from 'react'
import DateSelector from '../../component/DateSelector/DateSelector'
import * as echarts from 'echarts'
import request from '../../../util/request'
import { getNearOneMonth, getNeerOneWeek } from '../../../util/getNearOneMonth'
export default function () {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [newUserDashbord, setNewUserDashbord] = useState([[], []])
  const [userDashbord, setUserDashbord] = useState([[], []])
  const [userData, setUserData] = useState({})
  const [isall, setIsAll] = useState(true)
  const date_selector_ref = useRef()
  useEffect(() => {
    defaultDate()
  }, [])
  // 数据更新重绘图表
  useEffect(() => {
    lineInit_tdsj()
  }, [newUserDashbord, userDashbord])
  // 选择日期后回调返回开始和结束时间
  function getDateBack(value) {
    console.log(value)
    setIsAll(3)
    setStartDate(value.startDate)
    setEndDate(value.endDate)
    getlist(value)
  }
  // 打开日期选择器
  const openDateSelector = () => {
    date_selector_ref.current.showModal()
  }
  // 点击全部
  function defaultDate() {
    setIsAll(1)
    let value = getNearOneMonth()
    setStartDate(value.startDate)
    setEndDate(value.endDate)
    getlist(value)
  }
  function defaultDateWeek() {
    setIsAll(2)
    let value = getNeerOneWeek()
    setStartDate(value.startDate)
    setEndDate(value.endDate)
    getlist(value)
  }
  // 用户数据配置
  function lineInit_tdsj() {
    let chartDom = document.getElementById('yhsj')
    let myChart = echarts.init(chartDom)
    option_yhsj && myChart.setOption(option_yhsj)
  }
  // 获取用户数据
  function getlist(value) {
    request
      .post({
        method: 'iot.back.fws.iot.ls.dashboard.user',
        biz_content: JSON.stringify({
          begin: value.startDate,
          end: value.endDate,
        }),
      })
      .then(res => {
        if (res.data.code == '10000') {
          let newUserDashbord = [[], []]
          let userDashbord = [[], []]
          res.data.result.newUserDashbord.map((e, i) => {
            e.key = i
            newUserDashbord[0].push(e.date)
            newUserDashbord[1].push(e.num)
          })
          res.data.result.userDashbord.map((e, i) => {
            e.key = i
            userDashbord[0].push(e.date)
            userDashbord[1].push(e.num)
          })
          setNewUserDashbord(newUserDashbord)
          setUserDashbord(userDashbord)
          setUserData(res.data.result)
        }
      })
  }
  const colors = ['#c9fc7d', '#70f5dd']
  const option_yhsj = {
    color: colors,
    tooltip: {
      show: true,
      trigger: 'axis',
      //show: true,   //default true
      // showDelay: 0,//显示延时，添加显示延时可以避免频繁切换
      // hideDelay: 50,//隐藏延时
      // transitionDuration: 0,//动画变换时长
      // backgroundColor: '#fff',//背景颜色（此时为默认色）
      // borderRadius: 8,//边框圆角
      padding: [0, 10, 10, 10], // [5, 10, 15, 20] 内边距
      // position: function (p) {
      //     // 位置回调
      //     // console.log && console.log(p);
      //     return [p[0] + 10, p[1] - 10];
      // },
      formatter: function (params, ticket, callback) {
        var res = ''
        let newParams = params.reverse()
        for (var i = 0, l = newParams.length; i < l; i++) {
          res +=
            '<br/>' +
            newParams[i].axisValue +
            '<br/>' +
            newParams[i].axisValueLabel +
            ' :&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp  ' +
            newParams[i].value +
            '<br/>' //鼠标悬浮显示的字符串内容
        }
        return res
      },
    },
    // formatter: function(v){
    //     console.log(v)
    // },
    legend: {},
    grid: {
      top: 20,
      bottom: 20,
      right: 10,
    },
    xAxis: [
      {
        type: 'category',
        axisTick: {
          alignWithLabel: false,
        },
        axisLine: {
          show: false,
        },

        axisLabel: {
          textStyle: {
            color: '#FFFFFF', //坐标值得具体的颜色
            fontSize: 10, //设置为零隐藏文字
          },
        },
        axisPointer: {
          label: {
            formatter: function (params) {
              return '新增用户 '
            },
          },
        },
        // prettier-ignore
        data: userDashbord[0],
        splitLine: {
          // show: false, // X轴线 颜色类型的修改
          lineStyle: {
            type: 'dashed',
            color: '#42ad7b',
          },
        },
      },

      {
        axisLabel: {
          textStyle: {
            color: '#FFFFFF', //坐标值得具体的颜色
            fontSize: 0.01, //设置为零隐藏文字  设置为零效果不好所以默认0.01
          },
        },
        type: 'category',
        axisTick: {
          alignWithLabel: false,
        },
        axisLine: {
          show: false,
        },
        axisPointer: {
          label: {
            formatter: function (params) {
              return ' 当日用户'
            },
          },
        },
        // prettier-ignore
        data: newUserDashbord[0],
        splitLine: {
          show: true, // X轴线 颜色类型的修改
          lineStyle: {
            type: 'dashed',
            color: '#7869c3',
          },
        },
      },
    ],
    yAxis: {
      type: 'value',
      axisLabel: {
        textStyle: {
          color: '#FFFFFF', //坐标值得具体的颜色
          fontSize: 0, //设置为零隐藏文字
        },
      },
      splitLine: {
        show: false, // X轴线 颜色类型的修改
        lineStyle: {
          type: 'dashed',
          color: '#292c38',
        },
      },
    },
    series: [
      {
        type: 'line',
        xAxisIndex: 1,
        smooth: true,
        emphasis: {
          focus: 'series',
        },
        data: userDashbord[1],
        lineStyle: {
          width: 5,
          color: '#cdf97e',
        },
      },
      {
        type: 'line',
        smooth: true,
        emphasis: {
          focus: 'series',
        },
        data: newUserDashbord[1],
        lineStyle: {
          width: 5,
          color: '#78fada',
        },
      },
    ],
  }
  return (
    <>
      <div className={styles.box2}>
        <div className={styles.head}>
          <div className={styles.title}>用户数据</div>
          <div className={styles.countBox}>
            <span style={{ marginRight: '10px' }}>
              新增用户：{userData.todayNewUserNum}
            </span>
            <span>总用户：{userData.allUserNum}</span>
          </div>
          <div className={styles.date}>
            <div onClick={defaultDateWeek} className={styles.allbox}>
              <div className={styles.all}>
                {isall == 2 ? <div></div> : null}
              </div>
              <span>近一周</span>
            </div>
            <div onClick={defaultDate} className={styles.allbox}>
              <div className={styles.all}>
                {isall == 1 ? <div></div> : null}
              </div>
              <span>近一月</span>
            </div>
            <img
              src="https://sbag-small.oss-cn-huhehaote.aliyuncs.com/upload/img/web/ito/rl.png"
              alt=""
            />
            <span onClick={openDateSelector}>
              {isall == 3 ? startDate + '至' + endDate : '选日期'}
            </span>
          </div>
        </div>
        {/* chart */}
        <div className={styles.yhsj} id="yhsj">
          {' '}
        </div>
        <div className={styles.labels}>
          <div className={styles.name1}>当日用户</div>
          <div className={styles.name2}>新增用户</div>
        </div>
      </div>
      <DateSelector ref={date_selector_ref} getDateBack={getDateBack} />
    </>
  )
}
